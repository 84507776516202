import { useMutation, useQuery } from 'villus';
import {
  ExtendedCustomerCartDocument,
  ExtendedGuestCartDocument,
  type AppliedMwGiftCards,
  ApplyGiftCardToCartDocument,
  RemoveGiftCardFromCartDocument,
  ExtendGuestPaymentMethodsDocument,
  ExtendSetPaymentMethodOnCartFeesDocument,
  ExtendPaymentMethodsDocument,
} from '~/graphql/ExtendedCart';
import { resolveCartPromotions } from '~/utils/cart';
import type { CartPromotions, ExtendedCartItem } from '~/utils/types';

export type CartPriceRuleTier = {
  discountValue: number;
  isApplied: boolean;
  subtotalValue: number;
};

export type ExtendedCartState = {
  appliedGiftCard: AppliedMwGiftCards | undefined | null;
  cartGifts: ExtendedCartItem[] | [];
  cartPromotions: CartPromotions;
  cartPriceRuleDiscounts: {
    tiers: Array<CartPriceRuleTier>;
    eligibleTierIdx: number;
  };
};

const extendedCartState = reactive<ExtendedCartState>({
  appliedGiftCard: undefined,
  cartGifts: [],
  cartPromotions: {
    options: undefined,
    eligiblePromotionIdx: 0,
  },
  cartPriceRuleDiscounts: {
    tiers: [],
    eligibleTierIdx: 0,
  },
});

/**
 * Gift Cards
 */

export function useApplyGiftCard() {
  const { cartState, mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching } = useMutation(ApplyGiftCardToCartDocument);

  async function applyGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.value.cartId, gift_card_code: code },
    });

    if (error && /\[GraphQL\] Requested Gift Card doesn't exist/gm.test(error.message)) {
      throw new Error('invalidGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Used`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNoBalance');
    }

    if (error && /\[GraphQL\] This Gift Card is already in the Quote./gm.test(error.message)) {
      throw new Error('usedGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Inactive`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNotActive');
    }
    patchCartState(mapCartState(data?.response?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data?.response?.cart?.available_payment_methods);

    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    applyGiftCard,
  };
}

export function useRemoveGiftCard() {
  const { execute, isFetching } = useMutation(RemoveGiftCardFromCartDocument);
  const { resolveException } = useExceptions('cart');
  const { cartState, mapCartState, patchCartState } = useStoredCart();

  async function removeGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.value.cartId, gift_card_code: code },
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));
    patchExtendedCartState(mapExtendedCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data?.response?.cart?.available_payment_methods);
    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    removeGiftCard,
  };
}

export function useExtendedCartAttributes() {
  const { cartState } = useStoredCart();
  return {
    ...toRefs(extendedCartState),
    hasInvalidItems: computed(() => cartState.value.items.some((item: ExtendedCartItem) => item.isInvalidItem)),
  };
}

function mapExtendedCartState(cart?: Partial<ExtendCart>): ExtendedCartState {
  if (!cart) {
    return extendedCartState;
  }

  return {
    appliedGiftCard:
      cart?.applied_mw_gift_cards === null
        ? null
        : cart?.applied_mw_gift_cards?.[0] || extendedCartState.appliedGiftCard || undefined,
    cartPromotions: resolveCartPromotions(cart.cart_promotions, extendedCartState),
    cartGifts: [],
    cartPriceRuleDiscounts: resolveCarPriceRuleDiscounts(cart, extendedCartState),
  };
}

function patchExtendedCartState(newState: Partial<typeof extendedCartState>) {
  Object.keys(newState).forEach(key => {
    (extendedCartState as any)[key] = (newState as any)[key];
  });
}

/**
 * Fetch the applied gift card if exists on first load
 * @param cookies
 */

export function useExtendedCartSetup() {
  const { getExtendedGuestCart, isFetchingExtendedGuestCart } = useExtendedGuestCart();
  const { getExtendedCustomerCart, isFetchingExtendedCustomerCart } = useExtendedCustomerCart();
  const cart = useCookie('cart');
  const { user } = useAuth();
  const isLoggedIn = computed(() => !!user.value?.email);

  usePaymentMethods();

  if (cart.value && !isLoggedIn.value) {
    getExtendedGuestCart();
  }

  if (isLoggedIn.value) {
    getExtendedCustomerCart();
  }

  return {
    isFetchingExtendedCart: computed(() => isFetchingExtendedCustomerCart || isFetchingExtendedGuestCart),
  };
}

/**
 * get guest extended cart
 * update cartState and extendedCartState accordingly
 */
function useExtendedGuestCart() {
  const { cartState, mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching: isFetchingExtendedGuestCart } = useQuery({
    query: ExtendedGuestCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const { resolveException } = useExceptions('cart');

  async function getExtendedGuestCart() {
    const { data, error } = await execute({
      variables: {
        cartId: cartState.value.cartId,
      },
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
      patchExtendedCartState(mapExtendedCartState(data?.cart));
    }
  }

  return {
    getExtendedGuestCart,
    isFetchingExtendedGuestCart,
  };
}

/**
 * get customer extended cart
 * update cartState and extendedCartState accordingly
 */
function useExtendedCustomerCart() {
  const { mapCartState, patchCartState } = useStoredCart();
  const { execute, isFetching: isFetchingExtendedCustomerCart } = useQuery({
    query: ExtendedCustomerCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  async function getExtendedCustomerCart() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
      patchExtendedCartState(mapExtendedCartState(data?.cart));
    }
  }

  return {
    getExtendedCustomerCart,
    isFetchingExtendedCustomerCart,
  };
}

export function useExtendPaymentMethods() {
  const { cartState } = useStoredCart();

  const {
    execute: executeExtendGuestPaymentMethods,
    data: extendGuestPaymentMethods,
    isFetching: isFetchingExtendGuestPaymentMethod,
  } = useQuery({
    query: ExtendGuestPaymentMethodsDocument,
    variables: {
      cartId: cartState.value.cartId,
    },
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const {
    execute: executeExtendCustomerPaymentMethods,
    data: extendCustomerPaymentMethods,
    isFetching: isFetchingExtendCustomerPaymentMethods,
  } = useQuery({
    query: ExtendPaymentMethodsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  return {
    executeExtendCustomerPaymentMethods,
    executeExtendGuestPaymentMethods,
    extendedPaymentMethods: computed(
      () =>
        extendCustomerPaymentMethods.value?.cart?.paymentMethods ||
        extendGuestPaymentMethods.value?.cart?.paymentMethods ||
        [],
    ),
    isExtendPaymentMethodsLoading: computed(
      () => isFetchingExtendCustomerPaymentMethods.value || isFetchingExtendGuestPaymentMethod.value,
    ),
  };
}
export function useExtendPaymentFee() {
  const { cartState } = useStoredCart();
  const { execute } = useMutation(ExtendSetPaymentMethodOnCartFeesDocument);

  async function setPaymentMethodFees(code: string) {
    try {
      const { data } = await execute({
        input: {
          cart_id: cartState.value.cartId,
          payment_method: {
            code,
          },
        },
      });
      return data?.response;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }
  return {
    setPaymentMethodFees,
  };
}
